
$background-color: rgb(245, 245, 245);

html, body, #root, .App {
  height: 100%;
  font-family: "IBM Plex Sans", sans-serif;
}

.App {
  background-color: $background-color;
  height: 100%;
}

.admin-Page {
  padding: 20px;
}


.user-Page {
  background-color: $background-color;
  height: 100%;
}

// Margins in user-page and page-content are to account for nav components
.user-Page.show-nav {
  padding-top: 68px;
}

.pulse-Page.show-nav {
  padding-top: 68px;
}

.page-content {
  padding-left: 200px;
  width: 100%;
  transition: padding-left 0.2s ease-in-out;
  background-color: $background-color;
}

.full-page-content {
  padding-left: 56px;
  width: 100%;
  transition: padding-left 0.2s ease-in-out;
  background-color: $background-color;
}

.content-page-body {
  background-color: #FFFFFF;
  padding: 40px;
  margin: 20px;
  box-shadow: 0px 6px 6px 0px #BABABA40;
  border-radius: 4px;
  height: fit-content;
}

.Disabled, .disabled {
  transition: opacity 0.4s;
  opacity: 0.5;
  pointer-events: none;
}

.user-Page {
  & > .rs-container {
    min-height: 100%;
    width: 100%;

    & > div {
      width: 100%;
      min-height: 100%;
    }
  }
}


@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.spin {
  animation: spin 0.7s linear infinite;
}

$primary-text: #292B2E;
$secondary-text: #6F6F6F;

.page {
  $spacing: 20px;
  width: calc(100% - #{$spacing * 2});
  background-color: #FFFFFF;
  padding: $spacing * 2;
  margin: $spacing;
  border-radius: 8px;
  position: relative;

  &.padding-none {
    padding: 0 0 0 0;
  }

  .top-right-actions {
    position: absolute;
    top: $spacing;
    right: $spacing;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
  }

  &.bottom {
    margin-top: -3 * $spacing;
  }

  &.top {
    padding-bottom: 1px;
  }

  .page-break {
    position: relative;
    height: 28px;
    margin: 40px 0;
    margin-left: -#{$spacing * 2};
    background-color: $background-color;
    width: calc(100% + #{$spacing * 4});
  }

  .page-break::before {
    content: '';
    position: absolute;
    bottom: calc(100% - 4px);
    left: 0;
    height: 20px;
    width: 100%;
    border-radius: 8px;
    background-color: white;
  }

  .page-break::after {
    content: '';
    position: absolute;
    top: calc(100% - 4px);
    left: 0;
    height: 20px;
    width: 100%;
    border-radius: 8px;
    background-color: white;
  }
}

.footer-actions {
  text-align: right;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .rs-btn {
    margin-left: 10px;
  }

  .next-btn {
    min-width: 215px;
  }
}

.page-header {
  margin-bottom: 20px;

  .header-text {
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: $primary-text;
  }

  .help-text {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: $secondary-text;
  }
}

.page-panel {
  @extend .page;
  border: 1px solid #EAECEF;
  margin: 20px 0;
  width: 100%;

  &.gray {
    background: var(--Blue-Grey-Shade-Light, rgba(188, 210, 225, 0.10));
    border: none;
  }

  .header-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    margin-bottom: 20px;
  }
}

.page-content-box {
  border: 1px solid #EAECEF;
  border-radius: 8px;
  padding: 16px 24px;
  color: #6F6F6F;
  margin-top: 8px;
  margin-bottom: 24px;

  &.dark {
    background: var(--Blue-Grey-Shade-Light, rgba(188, 210, 225, 0.10));
    border: none;
  }
}

.split {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.split-cols {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;

  > div {
    width: 100%;
  }
}

.selected-items-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .selected-item {
    border-radius: 12px;
    padding: 4px 8px;
    margin-right: 5px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #8793A6;
    background-color: #F0F4F7;

    &:last-child {
      margin-right: 0;
    }

    .selected-remove {
      margin-left: 6px;
      color: white;
      background-color: black;
      border: 1px solid black;
      font-weight: 700;
      font-size: 10px;
      padding: 2px;

      &:hover {
        color: black;
        background-color: white;
      }

      .rs-btn-icon.rs-btn-xs > .rs-icon {
        font-size: 10px;
      }
    }
  }
}

/* overrides */
.clickable-row {
  cursor: pointer;
}

.filter-action-button {
  background: transparent;
  padding: 8px 12px;
  color: #575757;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 14px;
  gap: 10px;
  border: 1px solid #e5e5ea;

  &:hover {
    background: #f0f4f7;
  }
}

button:focus, input:focus, div, ul:focus, a:focus { // coming from design builder i think
  outline: none !important;
}

.rs-drawer-open {
  font-family: "IBM Plex Sans", sans-serif;
}

.rs-drawer-body {
  max-height: none !important;
}

.action-drawer {
  .rs-drawer-content {
    display: grid;
    grid-template-rows: 1fr auto;
  }

  .rs-drawer-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    width: 100%;
    background-color: #fff;
    gap: 2rem;
    box-shadow: 0px -5px 5px -5px rgba(0, 0, 0, 0.2);
    z-index: 2;

    & > * {
      width: 100%;
    }
  }
}

.rs-panel {
  background-color: white;
}

.rs-panel-btn:hover {
  background: transparent;
}

.rs-stack-item.rs-radio-tile-icon {
  width: fit-content;
}

.rs-dope {
  .rs-panel-header, .rs-panel-btn, .rs-panel-body {
    padding: 0;
  }
  .rs-panel.rs-panel-collapsible {
    width: 100%;
  }
}

.rs-form-control-label {
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 13.2px */
  letter-spacing: 0.22px;
  color: #8793A6;
}

.rs-input {
  border-radius: 4px;
  border: 1px solid #EAECEF;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: $primary-text;
}

.white-dropdown-button > button {
  background-color: #FFFFFF;
}

.custom-dropdown-button > button {
  width: fit-content;
  color: #FFFFFF;
  background-color: #EA0029;
  border: 1px solid #EA0029;
  padding: 7px 15px;
  border-radius: 21px;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  cursor: pointer;
  margin-left: 10px;
}

.custom-dropdown-button > button:hover {
  color: #FFFFFF;
  background-color: #EA0029;
}

.navbar-dropdown-button.rs-dropdown > button {
  background: none;
  display: flex;
  align-items: center;
}

.drop-nav-item.rs-dropdown-item.rs-dropdown-item-with-icon:hover {
  background-color: #F0F4F7;
  width: 200px;
  margin-left: -20px;
  padding: 10px 0 10px 20px !important;
}

.hidden-break {
  border-bottom: none;
}

.custom-drawer-padding {
  padding: 16px 24px;
}

.rs-dropdown-toggle {
  height: 44px;
}

.rs-dropdown-toggle-caret {
  display: none;
}

.rs-stack-item {
  width: 100%;
  overflow: inherit !important;
}

.rs-sidenav-nav.rs-nav.rs-nav-default.rs-nav-vertical {
  margin-bottom: 0;
}
// TODO was there a reason for this...
// input[readonly] {
//   pointer-events: none;
// }

.dope-filter-dropdown .rs-dropdown-toggle {
  height: 30px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  padding: 2px 10px;
  border: 1px solid #EAECEF;
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.selected-filter > .rs-dropdown-toggle {
  color: #255FDF;
  background: #F0F4F7;
}

.rs-drawer-wrapper {
  z-index: 1060;
}

// MAJOR TODO: rsuite modals and drawers are not using correct font-family
.rs-modal-wrapper {
  z-index: 1062;
  font-family: "IBM Plex Sans", sans-serif;
}

.rs-modal {
  z-index: 1063;
}

.rs-modal-backdrop {
  z-index: 1061;
}

.pac-container { // google places autocomplete
  z-index: 1060;
}

// custom width for invoice drawers
.invoice-drawer.rs-drawer-right.rs-drawer-sm.rs-anim-slide-in.rs-anim-right.rs-drawer {
  width: 744px;
}

.dispatch-mailer {
  display: flex;
  align-items: start;
  width: 100%;
  gap: 10px;

  img {
    height: auto;
    max-height: 100px;
    object-fit: contain;
    max-width: 150px;
  }

  .product-name {
    padding: 2px 4px;
    border-radius: 4px;
    background: var(--ui-background, #F0F4F7);
    font-size: 11px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.22px;
    text-transform: uppercase;
    color: var(--text-link-blue, #255FDF);
    display: inline-block;
  }
}

.dispatch-list {
  .rs-row {
    border: 1px solid #EAECEF;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 4px;

    &.header {
      font-weight: 600;
    }

    &:not(.header) {
      .rs-col {
        min-height: 100px;
        display: flex;
        align-items: center;
      }
    }
  }
}


// all ye who enter here, abandon hope
// yeeee be warned

.pirate-ship {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 142px;
  background-image: url('./arr.png');
  background-size: cover;
  background-repeat: no-repeat;
  animation: sail 15s linear infinite, rideWaves 2s ease-in-out infinite;
  z-index: 696969;

  .fire {
    position: absolute;
    bottom: 20px;
    left: 20px;
    width: 100%;
    height: 100%;
    background-image: url('./fire.png');
    background-size: cover;
    background-repeat: no-repeat;
    transform: scale(0);
    transition: transform 0.25s ease-in;
  }

  &.explode .fire {
    transform: scale(3);
  }
}

.scalawag-right {
  position: absolute;
  left: 70%;
  width: 200px;
  height: 142px;
  background-image: url('./scalawag.png');
  background-size: cover;
  background-repeat: no-repeat;
  animation: 5s sayHelloTop ease-in-out;
  transform: rotate(180deg);
  z-index: 696969;
}

.scalawag-left {
    position: absolute;
    left: 20%;
    width: 200px;
    height: 142px;
    background-image: url('./scalawag.png');
    background-size: cover;
    background-repeat: no-repeat;
    animation: 5s sayHelloBottom ease-in-out;
    z-index: 696969;
}

@keyframes sayHelloTop {
    0%, 100% { top: -142px }
    50% { top: 0  }
}

@keyframes sayHelloBottom {
    0%, 100% { bottom: -142px }
    50% { bottom: 0  }
}

@keyframes sail {
  0%, 100% {
    left: -220px;
    transform: scaleX(1);
  }
  49% { transform: scaleX(1); }
  50% { left: 100%; }
  51% { transform: scaleX(-1); }
  99% { transform: scaleX(-1); }
}

@keyframes rideWaves {
  0%, 100% { bottom: -5px; }
  50% { bottom: -25px; }
}
