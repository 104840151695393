.create-cancel {
  width: 127px;
}

.submit {
  width: 236px;
}

.dope-button {
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 24px;
  cursor: pointer;
  background-color: rgba(233, 238, 245, 1);
  color: rgba(23, 25, 28, 1);

  &:hover {
    background-color: #F4F6FA;
  }

  &:disabled {
    background: #eeee;
    color: #ACACAC;;
    cursor: not-allowed;
  }

  &.thin {
    height: 32px;
  }

  &.sm {
    font-size: 12px;
    line-height: 16px;
    padding: 4px 8px;
  }

  &.rectangle {
    border-radius: 8px;
  }

  &.filled {
    background: #EA0029;
    color: #FFFFFF;

    &:hover {
      background: #B40020;
    }

    &:disabled {
      background: #EAECEF;
      color: #ACACAC;
    }
  }
}

.dope-button.outlined-red {
  background: #FFFFFF;
  border: 1px solid #EAECEF;
  color: #EA0029;
}

.dope-button.outlined-red:hover {
  background: #EA00291F;
}

.dope-button.outlined-red:active {
  border: #EA0029 1px solid;
}

.dope-button.outlined-red:disabled {
  background: #FFFFFF;
  border: 1px solid #EAECEF;
  color: #EAECEF;
}

.dope-button.tonal {
  background: #F0F4F799;
  color: #292B2E;
}

.dope-button.tonal:hover {
  background: #F0F4F766;
  box-shadow: 0 1px 2px 0 #00000040, 0 1px 3px 1px #00000026;
}

.dope-button.tonal:disabled {
  background:#EBEBEF;
  color: #ACACAC;
}

.dope-button.tonal:disabled:hover {
  box-shadow: none;
}

.dope-button.outlined-black {
  background: #FFFFFF;
  border: 1px solid #EAECEF;
  color: #292B2E;
}

.dope-button.outlined-black:hover {
  background: #F0F4F74D;
}

.dope-button.outlined-black:disabled {
  color: #ACACAC;
  border: 1px solid #ACACAC;
}

.dope-button.outlined-black:disabled:hover {
  background: #FFFFFF;
}

.dope-button.text-button {
  background: transparent;
  color: #292B2E;
}

.dope-button.text-button:hover {
  background: #F0F4F74D;
}

.dope-button.text-button:disabled {
  color: #ACACAC;
}

.dope-button.text-button:disabled:hover {
  background: transparent;
}

.dope-button.elevated {
  box-shadow: 0 1px 2px 0 #00000040, 0 1px 3px 1px #00000026;
  color: #255FDF;
  background: #F0F4F799;
}

.dope-button.elevated:hover {
  background: #EBEBEF;
}

.dope-button.elevated:disabled {
  color: #ACACAC;
  background: #EBEBEF;
}

.dope-button.filled-blue {
  background: #255FDF;
  border: 1px solid #255FDF;
  color: #FFFFFF;
}

.dope-button.filled-blue:hover {
  background: #1A4ABF;
}


.dope-button.outlined-blue {
  background: #FFFFFF;
  border: 1px solid #255FDF;
  color: #255FDF;
}

.dope-button.outlined-blue:hover {
  background: #255FDF1F;
}


.dope-button.text-link {
  background: transparent;
  color: #255FDF;
  font-weight: 600;
}

.dope-button.text-link:hover {
  text-decoration: underline;
}

.dope-button.text-link:disabled {
  color: #8793A6;
}

.dope-button.text-link:disabled:hover {
  text-decoration: none;
}

.dope-button.filled-black {
  background:  rgba(23, 25, 28, 1);
  color: #FFFFFF;
}

.dope-button.filled-black:hover {
  background: rgba(23, 25, 28, 0.8);
}

.dope-button.filled-black:disabled {
  background: #EAECEF;
  color: #ACACAC;
  cursor: not-allowed;
}

.dope-button.filled-gray {
  background: #F0F4F7;
  color: #292B2E;
}

.dope-button.icon-clickable {
  display: flex;
  flex-direction: column;
  height: 110px;
  width: 116px;
  border-radius: 16px;

  .icon {
    height: 56px;
    width: 56px;
    margin: 0 auto;
    border-radius: 16px;
    background: #494B4E;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
  }

  .label {
    margin-top: 8px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #323742;
  }
}

.dope-button.icon-clickable:hover {
  box-shadow: 0 6px 6px 0 #14171908, 0 1px 1px 0 #14171905;
  border: 1px solid #F3F3F3;
}

.dope-button.green {
  background: #00A82F;
  color: #FFFFFF;

  &:hover {
    background: #008A25;
  }
}

.action-button {
  width: 140px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.dropdown-button > .rs-dropdown-toggle {
  height: 40px;
}

.dropdown-title {
  border-right: 1px solid #EAECEF;
  padding: 8px 24px 8px 8px;
}

.dropdown-icon {
  padding: 12px;
}

.rs-dropdown-toggle.rs-dropdown-toggle-no-caret.rs-btn.rs-btn-default {
  margin: 0;
  padding: 0;

  &:hover {
    background: none;
  }
}

.audience-page-offset {
  margin-top: 14px;
}
