.dope-modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  color: rgba(23, 25, 28, 1);

  z-index: 1063;

  &--backdrop {
    background-color: rgba(23, 25, 28, 0.5);
  }

  &--centered {
    text-align: center;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding-top: 1rem;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 2rem;
    transform: translate(calc(-50% - 2rem), calc(-50% - 2rem));
    background-color: #fff;


    width: 100%;
    max-width: 560px;

    border-radius: 40px;
    padding: 36px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  }

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 25, 28, 0.5);
    z-index: -1;
  }

  &__logo {
    max-height: 80px;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 1rem;
    // border: 2px solid #424242;
    // border-radius: 100%;
    width: fit-content;
    align-self: center;
    justify-self: center;
  }

  &__header {
    text-align: center;
    margin-bottom: 1rem;

    h3 {
      font-size: 1.5rem;
      font-weight: 700;
      // margin-bottom: 1rem;
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      margin: 0;
      margin-top: 1rem;
    }
  }

  &__body {
    &:not(:empty) {
      margin: 1rem 0;
    }
  }

  &--confirmation {

    & h3 {
      font-size: 1.3rem;
    }

    & h4 {
      font-size: 1.2rem;
    }

    & .dope-modal__footer {
      flex-direction: column-reverse;

      & .dope_button--main {
        width: 100%;
        margin-bottom: 1rem;

      }
    }
  }
}

@media (min-width: 512px) {
  .dope-modal {
    &__header {
      text-align: left;

      h3 {
        font-size: 2rem;
      }
    }

    &__footer {
      flex-direction: row;
    }

    &--confirmation {
      & .dope-modal__footer {
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;

        & .dope_button--main {
          width: auto;
          margin-bottom: 0;
        }
      }
    }
  }
}
